<template>
  <div>
    <Header :title="'转出'" :back="'my'"/>
    <div class="buy">
      <div class="tab-box2">
        <el-tabs v-model="activeName2">
          <el-tab-pane label="我的银行卡" name="ka"></el-tab-pane>
          <el-tab-pane label="木星国际账户" name="hu"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="tab-content-box">
      <div class="three-box" v-show="activeName2=='ka'">
        <div class="skzh flex-box" v-if="!banksList.length"  @click="changeBank">
          <i class="el-icon-circle-plus-outline theme-color" style="font-size:2rem;margin-right:6px" ></i>
          <span> 选择收款账户</span>
        </div>
        <div class=" box-items flex-box bank" v-else v-for="item in banksList" :key="item.id" @click="changeBank">
          <!-- <div class="bank-icon"><img :src="require('@/assets/img/1.png')"/></div> -->
          <div v-if="item.type=='hongkong'">
            <div class="theme-color">{{item.enbank}}(尾号{{item.tailnumber}})</div>
            <div class="sub">{{item.bank}}，代码{{item.bankcode}}</div>
          </div>
          <div v-else>
            <div class="theme-color">{{item.bank}}(尾号{{item.tailnumber}})</div>
            <div class="sub">{{item.address}}，SWIFT码{{item.swift}}</div>
          </div>
          <div class="icon-right"><i class="el-icon-arrow-right"></i></div>
        </div>
        
        <div class="buy box-items">
          <div class="buy-money">提现金额</div>
          <div class="buy-input">
            <div class="buy-fh">$</div>
            <el-input v-model="meney" @focus="tips=''" :placeholder="placeholder"/>
            <div class="buy-all" @click="meney=cash">全部</div>
          </div>
          <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
          <div>
            <el-button class="defined-btn2" @click="handerTx">确认提现</el-button>
          </div>
        </div>
      </div>
      <div class="three-box" v-show="activeName2=='hu'">
        <div class="box-items buy">
          <el-input
            class="form-items"
            placeholder="请输入收款人木星国际ID"
            v-model="id"
            @focus="tips=''"
            prefix-icon="el-icon-bank-card"
            clearable>
          </el-input>
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入收款人姓名"
            v-model="name"
            prefix-icon="el-icon-user"
            clearable>
          </el-input>
          <div class="buy-input2 form-items" style="padding:0px">
            <div class="buy-fh2 theme-color">$</div>
            <el-input v-model="money" @focus="tips=''" :placeholder="placeholder2" clearable/>
            <div class="buy-all2 theme-color" @click="money=cash">全部</div>
          </div>
          <el-input
            class="form-items"
            @focus="tips=''"
            placeholder="请输入与对方关系（朋友/家人/同事）"
            v-model="relationship"
            prefix-icon="el-icon-share"
            clearable>
          </el-input>
          <div v-if="tips" class="error-tips"><i class="el-icon-error"></i>{{tips}}</div>
          <div class="form-items"><button class="wid-btn" style="width:96%" @click="handerConfirm">确认转账</button></div>
        </div>
      </div>
    </div>
   <el-dialog
      :visible.sync="drawer"
      width="85%"
      :show-close="false"
      custom-class="pwd-box"
      >
      <div class="draw-box">
        <div class="close-icon" @click="drawer=false"><i class="el-icon-circle-close theme-color"></i></div>
        <div class="draw-title">请输入交易密码</div>
        <div>
          <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password"
            @complete="onPasswordInputComplete"/>
        </div>
        <div class="draw-pwd theme-color" @click="forgetPwd">忘记密码？</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Password from '@/components/Password/Index'

export default {
  components: {
    Header,
    Password
  
  },
  data(){
    return {
      drawer:false,
      password:'',
      activeName2:"ka",
      meney:null,
      id:'',
      name:'',
      money:'',
      relationship:'',
      tips:'',
      cash:null,
      placeholder:'',
      placeholder2:'',
      banksList:[],
      ids:this.$route.query.id
    }
  },
  created(){
    this.getMoney()
    this.getBanksData()
   
  },
  methods:{
    getMoney(){
      this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'money'}}).then(res => {
        this.placeholder = "可提现金额"+res.money.use+"美元"
        this.placeholder2 = "可转账金额"+res.money.use+"美元"
        this.cash = res.money.cash
      })
    },
    // 提现
    handerTx(){
      if(!this.meney){
        this.tips = "请输入提现金额";
        return false
      }
      if(this.data2float(this.meney)==false){
        this.tips = "请输入最多2位小数的数据类型";
        return false
      }
      if(!this.banksList.length){
        this.tips = "请选择收款账户";
        return false
      }
      this.drawer = true
    },
    // 输入交易密码提现
    onPasswordInputComplete(){
      if(this.activeName2=="ka"){
        let params ={
          type:this.banksList[0].type,
          payee:this.banksList[0].payee,
          bankname:this.banksList[0].bank,
          bankaccount:this.banksList[0].number,
          bankcode:this.banksList[0].bankcode,
          bankaddress:this.banksList[0].address,
          bankswift:this.banksList[0].swift,
          money:this.meney,
          password:this.password,
        }
        // console.log(params)
        this.$store.dispatch('transation/withdraws',params).then(res => {
          if(res.msg=='提现请求成功'){
            this.$router.push({path:'transfersuccess',query:{type:'withdraws'}})
          }else{
            this.drawer= false;
            this.password=null
            this.$message({
              offset: 100,
              type: 'error',
              message: res.msg,
              customClass:'message-tips'
            });
            // this.tips= res.msg
          }
          // console.log(res)
        }).finally(()=>{
          this.password=null
        })
      }else if(this.activeName2=="hu"){
        
         let params ={
          money:this.money,
          password:this.password,
          payee_code:this.id,
          payee_name:this.name,
          relation:this.relationship
        }
        this.$store.dispatch('transation/transfer',params).then(res => {
          if(res.msg=='转账请求成功'){
            this.$router.push({path:'transfersuccess',query:{type:'transfer'}})
          }else{
            this.drawer= false;
            this.tips= res.msg
          }
        }).finally(()=>{
          this.password=null
        })
      }
      
    },
    
    getBanksData(){
      this.$store.dispatch('transation/getBanks').then(res => {
        if(res&&res.code=='success'&&res.result.length){
          this.banksListTemp = res.result
            if(!this.ids){
              this.banksList = res.result.slice(0,1)
            }else{
              this.banksList = res.result.filter(item=>item.id == this.ids)
            }
        }
      })
    },
    changeBank(){
      this.$router.push({path:'bank'})
    },
    handerConfirm(){
      if(!this.id){
        this.tips = "请输入收款人ID";
        return false
      }
      if(!this.name){
        this.tips = "请输入收款人姓名";
        return false
      }
      if(!this.money){
        this.tips = "请输入转账金额";
        return false
      }
      if(this.data2float(this.money)==false){
        this.tips = "请输入最多2位小数的数据类型";
        return false
      }
      if(!this.relationship){
        this.tips = "请输入与对方的关系";
        return false
      }
       let params = {
        type:'user',
        user_name:this.name,
        user_code:this.id
      }
      
      this.$store
        .dispatch('auth/checkscodes',params)
        .then(res => {
          if(res.code==-1){
            this.$message({
              offset: 100,
              type: 'error',
              message: res.msg,
              customClass:'message-tips'
            });
          }else{
            this.dialogC()
          }
        }) 
      
    },
    dialogC(){
    // 弹出层
    const h = this.$createElement;
        this.$msgbox({
          title: '',
          center: true,
          showCancelButton:false,
          message: h('div', null, [
            h('i', { class: 'el-icon-s-custom',style:'font-size:2rem'}, null),
            h('div', { style: 'text-align: center' }, this.name),
            h('div', { style: 'text-align: center;color:#464646'},"ID："+ this.id),
            h('div', { style: 'text-align: center;font-size:2rem;margin:1rem auto;' }, '$'+this.$options.filters.formatFloat(this.money)),
            h('div', { style: 'text-align: center' }, '免手续费')
          ]),
          confirmButtonText: '确定转账',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.drawer =true
              done();
            } else {
              done();
            }
          }
        }).then(action => {
          if(action=='confirm'){
           this.drawer =true
           this.password=null
          }
        });
     
    },
    forgetPwd(){
      this.$router.push({name:'forgetpwd'})
    }
  }
}
</script>
<style lang="scss" scoped>

.tab-box2{
  background: #fff;
   ::v-deep{
    .el-tabs{
      line-height:40px;
      margin-bottom:2.5%;
      .el-tabs__header{
        margin:0px;
      }
      .el-tabs__nav{
        width: 99%;
        margin-left:.5%
      }
      .el-tabs__item{
        width: 50%;
        text-align: center;
      }
      .el-tabs__active-bar{
        margin-left:22%
      }
    }
   }
}
.el-tabs__nav-wrap::after{
  border:none
}
.three-box{
  .flex-box{
    justify-content: center;
  }
  .box-num {
    font-size: 3rem;
    margin:10px;
    .percent{
      font-size: 1.8rem;
    }
  }
}

.buy{
  background: #fff;
}

.skzh{
  border-radius:8px;
  height: 5rem;
  border:1px dashed #002D91;
  margin:2.5%;
  line-height: 5rem;
  justify-content: center;
  align-items: center;
}
.buy{
  .buy-money{
    // padding-left:3%;
    font-size: .9rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  .buy-input{
    margin: 10px 0px;
    position: relative;
    .el-input{
      ::v-deep{
        .el-input__inner{
          padding:0% 7% ;
        }
      }
    }
    .buy-fh{
      position: absolute;
      top:10px;
      left: 3%;
      z-index: 2;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .buy-all{
      position: absolute;
      top:10px;
      right: 3%;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
   
  }
  .buy-input2{
    padding: 10px;
    position: relative;
    .el-input{
      // font-size: 16px;
      ::v-deep{
        .el-input__inner{
          padding:0% 7% ;
        }
      }
    }
    .buy-fh2{
      position: absolute;
      top:10px;
      left: 3%;
      z-index: 2;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .buy-all2{
      position: absolute;
      top:10px;
      right: 3%;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
   
  }
}
.form-items{
  margin-top:1rem;
}
.three-box .bank{
  margin-top:2.5%;
  align-items: center;
  justify-content:flex-start;
  flex-shrink: 0;
  margin-bottom:2.5%;
  position: relative;
  .icon-right{
    position: absolute;
    top:40%;
    right: 20px;
  }
  .bank-icon{
    width: 60px;
    text-align: center;
  }
  .sub{
    color: #464646;
    font-size: .9rem;
    margin-top:.5rem
  }
}
.draw-box{
  padding: 5%;
  :not(:first-child) div{
    margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
  .draw-title{
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #989898;
    text-align: center;
    padding-top:5px;
    padding-bottom:15px
  }
  .draw-pwd{
    margin-top:1rem;
    text-align: right;
    margin-right:5%;
    font-size: .8rem;
  }
}
::v-deep{
  .el-drawer__header{
    text-align: center;
  }
}
</style>